<script setup>

import { ref } from "vue"
import { useFbsStore } from "@/store/fbs"

const fbsStore = useFbsStore()

const fbss = ref([])
const loading = ref(true)
const filter = ref(null)
const totalRecords = ref(0)

const clearFilter = () => {
  filter.value = null
  search()
}

const search = (e = {}) => {
  loading.value = true

  const page = (e.page || 0) + 1
  fbsStore.index({ filter: filter.value, page }).then((res) => {
    fbss.value = res.data.fbss.data
    totalRecords.value = res.data.fbss.total

    loading.value = false
  })
}

search()

</script>

<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <h5>FBS</h5>
        <DataTable 
          class="p-datatable-gridlines" 
          dataKey="id" 
          filterDisplay="row" 
          :lazy="true"
          :loading="loading" 
          :paginator="true" 
          responsiveLayout="scroll"
          :rowHover="true" 
          :rows="10" 
          :totalRecords="totalRecords"
          :value="fbss" 
          @page="search($event)"
        >
          
          <template #header>
            <div class="flex justify-content-between flex-column sm:flex-row">
              <Button type="button" icon="pi pi-filter-slash" label="Clear" class="p-button-outlined mb-2" @click="clearFilter" />
              <div class="col-12 md:col-4">
                <div class="p-inputgroup">
                  <InputText v-model="filter" placeholder="Login ID, Campaign ID" />
                  <Button icon="pi pi-search" class="p-button-warning" @click="search" />
                </div>
              </div>
            </div>
          </template>
          <template #empty>
              No records.
          </template>
          <template #loading>
              Loading. Please wait.
          </template>

          <Column header="Options">
            <template #body="{data}">
              <router-link :to="`/fbss/${data.id}`">
                <Button icon="pi pi-eye" class="p-button-rounded p-button-primary mr-2 mb-2" />
              </router-link>

              <router-link v-for="account in data.account" :key="account.id" :to="`/members/${account.member_id}`">
                <Button icon="pi pi-users" class="mr-2 mb-2" />
              </router-link>
            </template>
          </Column>

        </DataTable>
      </div>
    </div>
  </div>
</template>
